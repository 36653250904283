
import moment from 'moment'
import Vue, { PropType } from 'vue'
import { getMarketingReportData } from '../../api/chisai/GCB2'
import Breadcrumbs from '../../components/Breadcrumbs.vue'
import BaseInputOutlined from '../../components/inputs/BaseInputOutlined.vue'
import CustomActivatorSelect from '../../components/inputs/CustomActivatorSelect.vue'
import SpinnerLoader from '../../components/SpinnerLoader.vue'
import { getMinDate, getMaxDate } from '@root/src/utils'

import _ from 'lodash'
import { Connector, Project } from '../../types/main'
import { CONNECTOR_TYPES } from '../../vars/general'
import PhoneLink from '@root/src/components/iconLinks/PhoneLink.vue'
import messageVarsService from '@root/src/helpers/gcb2/messageVars'
import AmoCrmLink from '@root/src/components/iconLinks/AmoCrmLink.vue'
import YClientsLink from '@root/src/components/iconLinks/YClientsLink.vue'
import ProfSalonLink from '@root/src/components/iconLinks/ProfSalonLink.vue'
import SonlineLink from '@root/src/components/iconLinks/SonlineLink.vue'
import AltegioLink from '@root/src/components/iconLinks/AltegioLink.vue'
import DikidiLink from '@root/src/components/iconLinks/DikidiLink.vue'
import ChatLink from '@root/src/components/iconLinks/ChatLink.vue'
export default Vue.extend({
    name: 'GCB2MarketingReport',
    components: { Breadcrumbs, SpinnerLoader, CustomActivatorSelect, BaseInputOutlined, AmoCrmLink, YClientsLink, ProfSalonLink, SonlineLink, AltegioLink, DikidiLink, ChatLink, PhoneLink },

    data: () => ({
        sort: { field: null, type: 'asc' } as any,
        pageLoading: true,
        dashboardDataLoading: false,
        type: 'staff',
        dateMenu: false,
        dates: [] as string[],
        dateFormat: 'DD.MM.YYYY',
        datePickerDateFormat: 'YYYY-MM-DD',
        dashboardData: {} as any,
        filters: { productsDisplay: 1 } as any,
        inititalFilters: {} as any,
        clientStatusesList: ['Новые', 'Повторные', 'Постоянные'],

        generalIndicatorsHeaders: [
            { text: 'Group', value: 'group' },
            { text: 'Новые', value: 'newClients.clientsCount' },
            { text: 'Новые', value: 'newClients.revenue' },
            { text: 'Повторные', value: 'repeatedClients.clientsCount' },
            { text: 'Повторные', value: 'repeatedClients.revenue' },
            {
                text: 'Постоянные',
                value: 'permanentClients.clientsCount',
            },
            { text: 'Постоянные', value: 'permanentClients.revenue' },
            { text: 'Выручка за все время', value: 'summaryRevenue' },
        ],
        generalIndicatorsSubHeaders: [
            { text: 'Клиенты', value: 'newClients.clientsCount' },
            { text: 'Выручка', value: 'newClients.revenue' },
            { text: 'Клиенты', value: 'repeatedClients.clientsCount' },
            { text: 'Выручка', value: 'repeatedClients.revenue' },
            { text: 'Клиенты', value: 'permanentClients.clientsCount' },
            { text: 'Выручка', value: 'permanentClients.revenue' },
            { text: ' Выручка за все время', value: 'summaryRevenue' },
        ],
    }),
    watch: {
        type: {
            immediate: true,
            handler() {
                this.$router.replace({
                    path: this.$router.currentRoute.path,
                    query: Object.assign({}, this.$router.currentRoute.query, { type: this.type }),
                })
            },
        },
    },
    computed: {
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        connector(): Connector | undefined {
            return this.project.connectors.find(el => el.connectorType === CONNECTOR_TYPES.GCB2)
        },
        ycConnector(): Connector | undefined {
            return this.project.connectors.find(el => el.connectorType === CONNECTOR_TYPES.yClients)
        },
        typesList(): any[] {
            const list = [{ id: 'staff', name: 'По сотрудникам' }]
            if (this.ycConnector) {
                list.push({ id: 'category', name: 'По категории клиента' })
                list.push({ id: 'acquisition', name: 'По источнику привлечения' })
            }
            return list
        },
        breadcrumbs(): Array<any> {
            return [
                {
                    text: 'ВАШИ ПРОЕКТЫ',
                    to: '/projects',
                },
                {
                    text: this.project.name,
                    to: `/project/${this.project.id}`,
                },
                {
                    text: `Когортный анализ`,
                    to: '',
                    disabled: true,
                },
            ]
        },
        dateRangeText(): string {
            const formatedDates = this.sortedDates.map(date =>
                moment(date, this.datePickerDateFormat).format(this.dateFormat)
            )
            return formatedDates.join(' - ')
        },
        sortedDates(): string[] {
            return [
                ...this.dates.sort(
                    (a, b) =>
                        moment(a, this.datePickerDateFormat).unix() -
                        moment(b, this.datePickerDateFormat).unix()
                ),
            ]
        },

        groupTitle(): string {
            if (this.dashboardData.type === 'category') {
                return 'Категория'
            }
            if (this.dashboardData.type === 'staff') {
                return 'Сотрудник'
            }
            if (this.dashboardData.type === 'acquisition') {
                return 'Канал привлечения'
            }
            return ''
        },
        groupFilterId(): string {
            if (this.dashboardData.type === 'category') {
                return 'categoryList'
            }
            if (this.dashboardData.type === 'staff') {
                return 'staffList'
            }
            if (this.dashboardData.type === 'acquisition') {
                return 'categoryList'
            }
            return ''
        },
        customerDetailsHeaders(): any[] {
            return [
                { text: 'Клиент', value: 'clientName', width: '17%' },
                { text: this.groupTitle, value: 'group' },
                { text: 'Дата первого посещения', value: 'firstVisitDate' },
                { text: 'Количество посещений', value: 'visitCount' },
                { text: 'Выручка', value: 'revenue' },
                { text: '', value: 'action', sortable: false, },
            ]
        },
        displayFilters(): any {
            return {
                groupList: Object.values(this.filters.groupList ?? {})
                    .filter((el: any) => el.selected)
                    .map((el: any) => el.option),
                statusList: Object.values(this.filters.statusList ?? {})
                    .filter((el: any) => el.selected)
                    .map((el: any) => el.option),
            }
        },
        filtersLists(): any {
            return {
                groupList: Object.values(this.filters.groupList ?? {}).map((el: any) => ({
                    id: el.option,
                    name: el.option,
                })),
                statusList: Object.values(this.filters.statusList ?? {}).map((el: any) => ({
                    id: el.option,
                    name: el.option,
                })),
                productsDisplay: [
                    { id: 1, name: 'Только услуги' },
                    { id: 2, name: 'Услуги и товары, купленные с услугами' },
                    { id: 3, name: 'Все услуги и товары' },
                ],
            }
        },
        queryFilters(): any {
            return _.pickBy(
                {
                    firstDateFrom: this.dates[0],
                    firstDateTo: this.dates[1],
                    [this.groupFilterId]: this.filters.groupList,
                    statusList: this.filters.statusList,
                    productsDisplay: this.filters.productsDisplay,
                },
                (v, k) => {
                    if (k === this.groupFilterId) k = 'groupList'
                    if (_.isObject(v) && _.isEmpty(v)) {
                        return false
                    }
                    if (
                        !_.isNil(v) &&
                        this.displayFilters[k] &&
                        (this.filtersLists[k].length === this.displayFilters[k].length ||
                            this.displayFilters[k].length === 0)
                    ) {
                        return false
                    }
                    return true
                }
            )
        },
    },
    methods: {
        dropFilters() {
            this.setDefaultDates()
            this.filters = Object.assign({}, this.inititalFilters)
            this.initData()
        },
        setDefaultDates() {
            this.dates = [
                moment()
                    .startOf('month')
                    .format(this.datePickerDateFormat),
                moment().format(this.datePickerDateFormat),
            ]
        },
        setFilterValue(filterId: string, val: any) {
            const updatedFilterVal = {}
            const selectedHmap = val.reduce((a: any, c: any) => Object.assign(a, { [c]: true }), {})
            this.filtersLists[filterId].forEach((el: any) => {
                _.setWith(
                    updatedFilterVal,
                    [el.id],
                    {
                        option: el.id,
                        selected: Boolean(selectedHmap[el.id]),
                    },
                    Object
                )
            })
            this.filters = Object.assign({}, this.filters, { [filterId]: updatedFilterVal })
        },
        changeSort(header: any) {
            if (this.sort.field === header.value && this.sort.type === 'asc') {
                this.sort = { field: header.value, type: 'desc' }
                return
            }
            if (this.sort.field === header.value && this.sort.type === 'desc') {
                this.sort = { field: null, type: 'asc' }
                return
            }
            this.sort = { field: header.value, type: 'asc' }
        },
        async initData() {
            this.dashboardDataLoading = true
            const { data, error } = await getMarketingReportData({
                projectId: this.project.id,
                type: this.type as any,
                filters: this.queryFilters,
            })
            if (error) {
                this.$store.dispatch('callNotify', 'Ошибка')
            }
            this.dashboardData = _.omit(data, 'filters')
            this.filters = Object.assign({}, this.filters, data.filters)
            this.dashboardDataLoading = false
        },
        getChatTextMessage(manualCommunication) {
            return messageVarsService.resolveVars(
                manualCommunication.textMessage,
                this.connector,
                manualCommunication
            )
        },
    },
    created() {
        if (this.$route.query.type) {
            this.type = this.$route.query.type as any
        }
    },
    async mounted() {
        this.inititalFilters = Object.assign({}, this.filters)
        this.pageLoading = true
        await this.setDefaultDates()
        await this.initData()
        this.pageLoading = false
    },
})
